import React from 'react';
import * as css from './Articles.module.css';

function Article({ article }) {
  return (
    <li className={css.Article}>
      <a href={article.url} target="_blank" rel="noopener noreferrer">
        <h3>{article.title}</h3>
      </a>
      <p>
        {article.source} — <small>{article.publishDate}</small>
      </p>
    </li>
  );
}

export default function Articles({ author, articles }) {
  return (
    <section className={css.Wrapper}>
      <ul className={css.Articles}>
        <h3>Articles by {author}</h3>
        {articles.map((article, i) => (
          <Article article={article?.node} key={i} />
        ))}
      </ul>
    </section>
  );
}
