import React from 'react';
import ContentfulText from '@components/ContentfulText';
import Text from '@components/Text';
import Articles from '@components/Articles';

import * as css from './Person.module.css';

export default function Person({ author, text, articles }) {
  return (
    <section className={css.Person}>
      <Text>
        <h2>{author}</h2>
        <ContentfulText text={text} />
      </Text>
      <Articles articles={articles.edges} author={author} />
    </section>
  );
}
