import React from 'react';
import { graphql } from 'gatsby';
import Person from '@components/Person';

export default function Page({ data }) {
  const contentfulTeamMember = data?.contentfulTeamMember;
  const allContentfulInTheNews = data?.allContentfulInTheNews;
  return (
    <Person
      text={contentfulTeamMember.biography}
      author={contentfulTeamMember.name}
      articles={allContentfulInTheNews}
    />
  );
}

export const query = graphql`
  query PageQuery($id: String!, $name: String!) {
    contentfulTeamMember(id: { eq: $id }) {
      biography {
        raw
      }
      name
    }
    allContentfulInTheNews(
      filter: { author: { eq: $name } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          author
          publishDate(formatString: "MMMM, YYYY")
          title
          url
          source
        }
      }
    }
  }
`;
